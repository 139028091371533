import gql from 'graphql-tag'

export default gql`{
  id
  available
  candidacies {
    id
    candidate{
      id
    }
    specialDate
  }
  description
  entity {
    id
    about
    logo
    name
    media{
      facebook
      linkedin
      twitter
    }
  }
  finalDate
  formation {
    course
    level
    status
    institution
    year
    weight
  }
  languages {
    name
    level
    weight
  }
  documents {
    name
    rule
  }
  locale
  category
  place
  private
  weight
      areaInterest
  professionalExperience {
    description
    yearsExperience
    weight
  }
  publicationDate
  status
  title
  type
  stages{
    id
    name
    tests {
      areas
      description
      duraction
      image
      openResponse
      title
      type
      questions {
        question
        objective
        implication
        file
        fileResponse
        openQuestion
        alternatives {
          answer
          value
          textResponse
        }
        totalCorrect
        percentage
        picture
      }
    }
  }
}`